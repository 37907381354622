<template>
  <v-app>
    <SideBar v-if="login.auth" :drawer.sync="drawer" />

    <v-app-bar v-if="login.auth" app color="teal darken-2" dense dark>
      <v-app-bar-nav-icon class="white--text" @click="drawer = true" />

      <div class="d-flex align-center">
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
      </div>

      <v-spacer />
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon dark @click="logout">
            <v-icon> mdi-logout-variant </v-icon>
          </v-btn>
        </template>
        <span v-text="$store.getters.getLangEn ? 'Logout' : 'Cerrar sesión'" />
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>

      <a
        href="https://sinergiamedica.north.3cx.us/LiveChat631792"
        target="_blank"
        class="btn-float white--text text-center"
      >
        <div>
          Ayuda
          <v-icon small color="white"> mdi-human-greeting-variant </v-icon>
        </div>
        <div>
          <small class="btn-float-subtxt"> 9am-6pm | Lun.-Vie. </small>
        </div>
      </a>
    </v-main>

    <v-footer app padless>
      <v-row dense>
        <v-col cols="6">
          <small
            v-if="login.auth"
            v-text="login.email"
            class="pl-1 font-weight-bold"
          />
        </v-col>
        <v-col cols="6" class="text-right">
          <small v-text="version" class="pr-1" />
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Axios from "axios";
import { URL_API, headers } from "./control";
import SideBar from "./components/SideBar.vue";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      drawer: false,
      login: this.$store.getters.getLogin,
      title: "Sinergia Médica",
      version: "v1.25.03.07",
    };
  },
  methods: {
    logout() {
      const lang_us = this.$store.getters.getLangUs;

      this.$swal
        .fire({
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Sí",
          icon: "warning",
          text: lang_us
            ? "Do you confirm to log out?"
            : "¿Confirma cerrar la sesión?",
        })
        .then((response) => {
          if (response.isConfirmed) {
            const token = this.login.token;
            this.$store.dispatch("logOutAction");

            Axios.get(`${URL_API}/auth/logout`, headers(token)).then(
              (response) => {
                if (response.data.success) {
                  window.location.assign("/inicio_sesion");
                } else {
                  console.log(response.data.message);
                  this.$swal.fire({
                    showConfirmButton: false,
                    icon: response.data.success ? "success" : "error",
                    text: response.data.message,
                    timer: 2000,
                  });
                }
              }
            );
          }
        });
    },
  },
};
</script>

<style>
.toolbar {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.v-main {
  background-color: #f8f9fa;
}

.swal2-html-container,
.swal2-styled {
  font-family: "Roboto" !important;
}

.btn-float {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #e91e62dc;
  padding: 3px 8px;
  position: fixed;
  bottom: 20px;
  right: 3px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  text-decoration: none;
}

.btn-float:hover {
  background-color: #e91e62;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  transform: translateX(-3px);
  cursor: pointer;
}

.btn-float-subtxt {
  font-size: 7px;
}
</style>